import { DOMAIN_REGEX } from '../../src/utils/constants'

export const createErrorMessage = (
  t,
  domain: string,
  restrictedDomains: Array<string>
) => {
  console.warn(domain, restrictedDomains)
  if (!DOMAIN_REGEX.test(domain)) {
    return t(
      'third-party-idp-settings.settingsConfirmationModal.errorLabel',
      'Error Label'
    )
  } else if (restrictedDomains?.some((item) => item === domain.slice(1))) {
    return t(
      'third-party-idp-settings.settingsConfirmationModal.restrictedDomainsMessage',
      'yopmail.com/gmail.com cannot be saved'
    )
  } else return null
}
