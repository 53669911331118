import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const ModalBox = styled.div`
  margin: ${primitives.layout.size0}px ${primitives.layout.size0}px
    ${primitives.layout.size5}px ${primitives.layout.size0}px;
`
export const ModalDescription = styled.div`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size2};
  font-weight: 300;
  line-height: ${primitives.typography.lineHeight3};
  letter-spacing: 0em;
  text-align: left;
`
export const ModalFooterBtnGrp = styled.div`
  &&& {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
`
