/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AOSConfigMgtSvcClient,
  IdConfigMgtSvcClient,
  Stack,
  IdLookUpMgtSvcClient,
  SecureStorageMgtSvcClient
} from '@jarvis/web-stratus-client'
import { PermissionSets } from '@jarvis/web-organization/dist/src/index'

//get permission sets for the given node
export const getPermissionSets = async (
  nodeId,
  stack,
  authProvider,
  mfeType
): Promise<any> => {
  let baseAuthProvider

  if (mfeType === 'customers') {
    baseAuthProvider = authProvider.createOrgedAuthProvider(nodeId)
  } else {
    baseAuthProvider = authProvider.createOrgedAuthProvider()
  }
  const permSetsClient = new PermissionSets(stack, baseAuthProvider)
  const permSets = await permSetsClient.getPermissionSets(nodeId)
  return permSets
}

// Fetch identity storage for org
export const fetchIdentityStorage = async (
  nodeId,
  stack,
  authProvider,
  mfeType
): Promise<any> => {
  let baseAuthProvider
  if (mfeType === 'customers') {
    baseAuthProvider = authProvider.createOrgedAuthProvider(nodeId)
  } else {
    baseAuthProvider = authProvider.createOrgedAuthProvider()
  }
  const identityStorageMgtClient = new SecureStorageMgtSvcClient(
    stack,
    baseAuthProvider
  )
  const response = identityStorageMgtClient.getIdentityStorageDetails(
    nodeId,
    0,
    100
  )
  return response
}

//Fetch Onboarding details for Org
export const fetchOnboarding = async (
  nodeId,
  stack,
  authProvider,
  mfeType
): Promise<any> => {
  let baseAuthProvider
  if (mfeType === 'customers') {
    baseAuthProvider = authProvider.createOrgedAuthProvider(nodeId)
  } else {
    baseAuthProvider = authProvider.createOrgedAuthProvider()
  }
  const aosMgtClient = new AOSConfigMgtSvcClient(stack, baseAuthProvider)
  const response = await aosMgtClient.getOrgidOnboardingDetails(nodeId, 0, 100)
  return response
}

//Function for sending the stack info for provider lookup service
function stackInfo(stack: Stack) {
  let userStack
  if (stack === 0) userStack = 'dev-'
  else if (stack === 1) userStack = 'pie-'
  else if (stack === 2) userStack = 'stage-'
  else userStack = ''
  return userStack
}

// For creating href URL for sericeDecsription lookup service
function hrefForServiceDesc(stack: Stack) {
  let href
  if (stack === 0) href = 'dev.authz.wpp.api.hp.com'
  else if (stack === 1) href = 'pie.authz.wpp.api.hp.com'
  else if (stack === 2) href = 'authz.stage.api.ws-hp.com'
  else href = 'authz.api.ws-hp.com'
  return href
}

// For creating the hash value
const SHA256 = require('crypto-js/sha256')

//Creating the config with mapping
export const getMapping = async (
  domain: string,
  tenantID: string,
  authorizationName: string,
  value,
  nodeId: string,
  rolePermID: string,
  stack,
  authProvider,
  baseConfigHP,
  baseConfigGoogle,
  baseConfigAzure,
  mfeType
): Promise<any> => {
  let baseAuthProvider
  if (mfeType === 'customers') {
    baseAuthProvider = authProvider.createOrgedAuthProvider(nodeId)
  } else {
    baseAuthProvider = authProvider.createOrgedAuthProvider()
  }
  let config
  const idConfigMgtClient = new IdConfigMgtSvcClient(stack, baseAuthProvider)
  const idLookupMgtClient = new IdLookUpMgtSvcClient(stack, baseAuthProvider)
  const identityStorageMgtClient = new SecureStorageMgtSvcClient(
    stack,
    baseAuthProvider
  )
  const domainValue = domain.toLowerCase().substring(1)
  const emailHash = SHA256(domainValue)
  if (value === 'HP') {
    config = await idConfigMgtClient.createHPConfig({
      type: 'special',
      base_config: baseConfigHP,
      discovery_endpoint:
        stack === 3
          ? 'https://directory.id.hp.com/directory/v1/.well-known/openid-configuration'
          : 'https://directory.stg.cd.id.hp.com/directory/v1/.well-known/openid-configuration',
      extra_auth_params: { domain_list: `${domainValue}` },
      onboarding_type: 'async'
    })
  } else if (value === 'Google') {
    config = await idConfigMgtClient.createGoogleConfig({
      type: 'special',
      base_config: baseConfigGoogle,
      discovery_endpoint:
        'https://accounts.google.com/.well-known/openid-configuration',
      extra_auth_params: { hd: `${domainValue}` },
      onboarding_type: 'async'
    })
  } else {
    config = await idConfigMgtClient.createAzureConfig({
      type: 'special',
      base_config: baseConfigAzure,
      discovery_endpoint:
        'https://login.microsoftonline.com/' +
        tenantID +
        '/v2.0/.well-known/openid-configuration',
      onboarding_type: 'async'
    })
  }
  const serviceDescription = await idLookupMgtClient.createServiceDescription(
    config.data?.uuid,
    hrefForServiceDesc(stack)
  )
  const provider = await idLookupMgtClient.createProvider(
    serviceDescription.data?.uuid,
    stackInfo(stack),
    authorizationName
  )
  const mapping = await idLookupMgtClient.createMapping(
    provider.data?.uuid,
    emailHash
  )
  if (mapping.status === 201) {
    const aosconfig = {
      threeid_config_id: config.data?.uuid,
      org_id: nodeId,
      perm_id: rolePermID
    }
    const aosMgtClient = new AOSConfigMgtSvcClient(stack, baseAuthProvider)
    const response = await aosMgtClient.createOnboardingId(aosconfig)
    // return response
    if (response.status === 201) {
      const secureConfig = {
        identity: domainValue,
        threeid_provider_id: config.data?.uuid,
        idp_type: value,
        idp_tenant_id: tenantID,
        authorization_name: authorizationName,
        org_id: nodeId
      }
      const response =
        await identityStorageMgtClient.createIdentityConfig(secureConfig)
      return response
    }
  }
}

//Fetch Config Details
export const fetchConfig = async (
  uuid,
  nodeId: string,
  stack,
  authProvider,
  mfeType
): Promise<any> => {
  let baseAuthProvider
  if (mfeType === 'customers') {
    baseAuthProvider = authProvider.createOrgedAuthProvider(nodeId)
  } else {
    baseAuthProvider = authProvider.createOrgedAuthProvider()
  }
  const idConfigMgtClient = new IdConfigMgtSvcClient(stack, baseAuthProvider)
  const response = await idConfigMgtClient.getConfig(uuid)
  return response
}
