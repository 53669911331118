import React from 'react'
import Component from 'src/components/MainComponent'
import { Container } from './styles'
import * as T from './types'

const App = ({ properties, ...props }: T.AppPropsType) => {
  return (
    <Container>
      <Component
        data-testid="third-party-idp-settings-component"
        properties={properties}
        {...props}
      />
    </Container>
  )
}

export default App
