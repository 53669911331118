import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@veneer/core'

const ContexualFooter = ({ onCancel, onSave, flag, loading, t }) => {
  return (
    <>
      <div
        data-testid="idp-settings-footer"
        className="idp-settings-footer"
      >
        <div className="btn-grp">
          <li>
            <Button
              data-testid="cancel-button"
              id="cancel-btn"
              appearance="secondary"
              onClick={() => onCancel()}
            >
              {t(
                'third-party-idp-settings.footerContextualMenu.cancel',
                'Cancel'
              )}
            </Button>
            <Button
              data-testid="save-button"
              id="save-btn"
              className={'saveButtonWidth'}
              appearance="primary"
              onClick={() => onSave()}
              loading={loading}
              disabled={flag}
            >
              {t('third-party-idp-settings.footerContextualMenu.save', 'Save')}
            </Button>
          </li>
        </div>
      </div>
    </>
  )
}

ContexualFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  flag: PropTypes.bool.isRequired
}

export default ContexualFooter
