import React, { useCallback, useEffect, useState } from 'react'
import {
  Select,
  TextBox,
  ProgressIndicator,
  useToast,
  Tooltip,
  IconInfo,
  Tabs
} from '@veneer/core'
import { SettingsState } from 'src/interfaces/settingsInterface'
import {
  getMapping,
  getPermissionSets,
  fetchIdentityStorage,
  fetchOnboarding
} from 'src/utils/api'
import { Location } from 'history'
import useShellRootContext from 'src/contexts/ShellRoot/useShellRootContext'
import NavigationBlockerModal from 'src/Shared-Components/NavigationBlockerModal/index'
import ContextualFooter from './ContexualFooter'
import { createErrorMessage } from 'src/utils/commonMethods'
import { RESTRICTED_DOMAINS, ECP_CLIENT } from 'src/utils/constants'
import {
  BasicTextItem,
  BasicTextItemStyle,
  BasicTextItemTitle,
  CardStyle,
  CardTitle,
  Container,
  CardTitleBottomHr,
  BasicSelectItemStyle
} from './styles'

const MainComponent = (props) => {
  const { mfeType, mockRestrictedDomains, nodeId, properties } = props
  const {
    t = (_key: string, defaultValue: string) => defaultValue,
    interfaces
  } = useShellRootContext()
  const authProvider = interfaces?.v2?.authProvider
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const events = interfaces?.v1?.events
  const featureFlags = interfaces?.v1?.featureFlags
  const navigation = interfaces?.v1?.navigation

  const HPBaseConfig = properties?.baseConfigHP
  const GoogleBaseConfig = properties?.baseConfigGoogle
  const AzureBaseConfig = properties?.baseConfigAzure

  const [restrictedDomains, setRestrictedDomains] = useState<Array<string>>([])

  useEffect(() => {
    const getFlag = async () => {
      const client = await featureFlags?.getClient(ECP_CLIENT)
      const featureFlag = (await client?.getFeatureFlag({
        key: RESTRICTED_DOMAINS,
        defaultValue: []
      })) as Array<string>
      setRestrictedDomains(featureFlag)
      return
    }
    if (mockRestrictedDomains) {
      setRestrictedDomains(mockRestrictedDomains)
    } else {
      getFlag()
    }
  }, [featureFlags, mockRestrictedDomains])

  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [selectValue, setSelectValue] = useState([
    t('third-party-idp-settings.settings.identifyProvidersSelected', 'HP')
  ])
  const [selectValueForRole, setSelectValueForRole] = useState([''])
  const [flag, setFlag] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [configDomain, setConfigDomain] = useState<string>()
  const [configAuthName, setConfigAuthName] = useState<string>()
  const [configTenantId, setConfigTenantId] = useState<string>('')
  const [rolesArray, setRolesArray] = useState([])
  const [settingsForm, setSettingsForm] = useState<SettingsState>({
    domain: '',
    tenantId: '',
    authorizationName: '',
    hasChanges: false,
    errorMessage: null,
    errorTenant: null,
    errorAuth: null
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const selectValues = [
    {
      value: 'HP',
      label: t(
        'third-party-idp-settings.settings.identifyProvidersSelected',
        'HP'
      )
    },
    {
      value: 'Google',
      label: t(
        'third-party-idp-settings.settings.identifyProvidersSelected3',
        'Google'
      )
    },
    {
      value: 'Azure',
      label: t(
        'third-party-idp-settings.settings.identifyProvidersSelected1',
        'Azure'
      )
    }
  ]

  const refreshData = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }

  useEffect(() => {
    refreshData()
    events?.addEventListener?.('ecp-banner-reload-call', refreshData)
    return () => {
      events?.removeEventListener?.('ecp-banner-reload-call', refreshData)
    }
  }, [events])

  type ModalInfoType = {
    location?: Location
    show?: boolean
    unregisterCallback?: () => void
  }

  const [modal, setModal] = useState<ModalInfoType>({
    show: false
  })

  const handleChange = (selectedOption) => {
    setSelectValue([selectedOption.value])
    setHasChanges(true)
    setSettingsForm({
      domain: '',
      tenantId: '',
      authorizationName:
        selectedOption.value === 'Azure'
          ? 'Microsoft'
          : selectedOption.value === 'HP'
            ? 'HP'
            : 'Google',
      hasChanges: false,
      errorMessage: null,
      errorTenant: null,
      errorAuth: null
    })
    selectedOption.value === 'Azure' ? setFlag(true) : setFlag(false)
  }

  const handleChangeForUserRole = (selectedOption) => {
    setSelectValueForRole([selectedOption.value])
    setHasChanges(true)
    setSettingsForm({
      domain: settingsForm.domain,
      tenantId: settingsForm.tenantId,
      authorizationName: settingsForm.authorizationName,
      hasChanges: settingsForm.hasChanges,
      errorMessage: settingsForm.errorMessage,
      errorTenant: settingsForm.errorTenant,
      errorAuth: settingsForm.errorAuth
    })
  }

  const handleFocus = () => {
    setHasChanges(true)
    if (settingsForm.domain === '') {
      setSettingsForm({
        domain: '@',
        tenantId: settingsForm.tenantId,
        authorizationName: settingsForm.authorizationName,
        hasChanges: true,
        errorMessage: null,
        errorTenant: settingsForm.errorTenant,
        errorAuth: settingsForm.errorAuth
      })
    }
    setIsDisabled(false)
  }
  const handleFocusTenantId = () => {
    setHasChanges(true)
    if (settingsForm.tenantId === '') {
      setSettingsForm({
        domain: settingsForm.domain,
        tenantId: '',
        authorizationName: settingsForm.authorizationName,
        hasChanges: false,
        errorMessage: settingsForm.errorMessage,
        errorTenant: null,
        errorAuth: settingsForm.errorAuth
      })
    }
    setIsDisabled(false)
  }
  const validateTenantId = (tenantId: string) => {
    const invalidTenantidMessage =
      tenantId.length === 0
        ? t(
            'third-party-idp-settings.settingsConfirmationModal.errorLabel',
            'Error Label'
          )
        : null
    const errorMessage = invalidTenantidMessage
    setIsDisabled(false)
    setSettingsForm({
      domain: settingsForm.domain,
      tenantId,
      authorizationName: settingsForm.authorizationName,
      hasChanges: !errorMessage,
      errorMessage: settingsForm.errorMessage,
      errorTenant: errorMessage || null,
      errorAuth: settingsForm.errorAuth
    })
  }

  const validateAuthName = (authorizationName: string) => {
    const invalidTenantidMessage =
      authorizationName.length === 0
        ? t(
            'third-party-idp-settings.settingsConfirmationModal.fieldBlank',
            'This field cannot be blank'
          )
        : null
    const errorMessage = invalidTenantidMessage
    setIsDisabled(false)
    setSettingsForm({
      domain: settingsForm.domain,
      tenantId: settingsForm.tenantId,
      authorizationName,
      hasChanges: !errorMessage,
      errorMessage: settingsForm.errorMessage,
      errorTenant: settingsForm.errorTenant,
      errorAuth: errorMessage || null
    })
  }

  const validateDomain = (domain: string) => {
    const errorMessage = createErrorMessage(t, domain, restrictedDomains)
    setIsDisabled(false)
    setSettingsForm({
      domain,
      tenantId: settingsForm.tenantId,
      authorizationName: settingsForm.authorizationName,
      hasChanges: !errorMessage,
      errorMessage: errorMessage || null,
      errorTenant: settingsForm.errorTenant,
      errorAuth: settingsForm.errorAuth
    })
  }

  const errorDomainMessage = settingsForm.domain
    ? settingsForm.errorMessage
    : t(
        'third-party-idp-settings.settingsConfirmationModal.fieldBlank',
        'This field cannot be blank'
      )
  const errorTenantidMessage = settingsForm.tenantId
    ? settingsForm.errorTenant
    : t(
        'third-party-idp-settings.settingsConfirmationModal.fieldBlank',
        'This field cannot be blank'
      )
  const errorAuthNameMessage = settingsForm.authorizationName
    ? settingsForm.errorAuth
    : t(
        'third-party-idp-settings.settingsConfirmationModal.fieldBlank',
        'This field cannot be blank'
      )

  const renderDomainTextbox = () => {
    return (
      <BasicTextItem>
        <BasicTextItemTitle data-testid="textbox-title">
          {t('third-party-idp-settings.settings.domainName', 'Domain')}
        </BasicTextItemTitle>
        {isLoading ? (
          <ProgressIndicator
            appearance="circular"
            behavior="indeterminate"
            data-testid="settings-form-elements-textbox-progress-indicator"
          />
        ) : (
          <TextBox
            data-testid="settings-form-elements-textbox"
            id="settings-form-elements-textbox"
            className="settings-form-elements-textbox"
            placeholder={t(
              'third-party-idp-settings.settings.enterDomain',
              'Enter a domain'
            )}
            onChange={(value) => validateDomain(value.toString())}
            onFocus={() => handleFocus()}
            value={settingsForm.domain}
            error={Boolean(settingsForm.errorMessage)}
            helperText={errorDomainMessage}
          />
        )}
      </BasicTextItem>
    )
  }

  const renderAuthNameTextbox = () => {
    return (
      <BasicTextItem>
        <BasicTextItemTitle data-testid="textbox-title">
          {t(
            'third-party-idp-settings.settings.authorizationName',
            'Authorization Name'
          )}
        </BasicTextItemTitle>
        {isLoading ? (
          <ProgressIndicator
            appearance="circular"
            behavior="indeterminate"
            data-testid="settings-form-elements-textbox-progress-indicator"
          />
        ) : (
          <TextBox
            id="settings-form-elements-textbox-3"
            className="settings-form-elements-textbox-3"
            data-testid="settings-form-elements-textbox-3"
            onChange={(value) => validateAuthName(value.toString())}
            value={settingsForm.authorizationName}
            error={Boolean(settingsForm.errorAuth)}
            helperText={errorAuthNameMessage}
          />
        )}
      </BasicTextItem>
    )
  }

  const renderTenantIdTextbox = () => {
    return (
      <BasicTextItem>
        <BasicTextItemTitle data-testid="textbox-title">
          {t('third-party-idp-settings.settings.tenantId', 'Tenant-id')}
        </BasicTextItemTitle>
        {isLoading ? (
          <ProgressIndicator
            appearance="circular"
            behavior="indeterminate"
            data-testid="settings-form-elements-textbox-2-progress-indicator"
          />
        ) : (
          <TextBox
            id="settings-form-elements-textbox-2"
            className="settings-form-elements-text-2"
            data-testid="settings-form-elements-textbox-2"
            placeholder={t(
              'third-party-idp-settings.settings.tenantIdPlaceholder',
              'Enter a tenant-id'
            )}
            onChange={(value) => validateTenantId(value.toString())}
            onFocus={() => handleFocusTenantId()}
            value={settingsForm.tenantId}
            error={Boolean(settingsForm.errorTenant)}
            helperText={errorTenantidMessage}
          />
        )}{' '}
      </BasicTextItem>
    )
  }

  const { addToast } = useToast()

  const createConfig = async () => {
    if (
      selectValue[0] !=
      t('third-party-idp-settings.settings.identifyProvidersSelected1', 'Azure')
    ) {
      setLoading(true)
      await getMapping(
        settingsForm.domain,
        settingsForm.tenantId,
        settingsForm.authorizationName,
        selectValue[0],
        nodeId,
        selectValueForRole[0],
        authStack,
        authProvider,
        HPBaseConfig,
        GoogleBaseConfig,
        AzureBaseConfig,
        mfeType
      )
        ?.then(() => {
          setLoading(false)
          setHasChanges(false)
          const text = t(
            'third-party-idp-settings.settingsConfirmationModal.saveMessage',
            'Your changes have been saved.'
          )
          addToast({
            id: 'success',
            type: 'positive',
            text
          })
        })
        .catch(() => {
          const text = t(
            'third-party-idp-settings.settingsConfirmationModal.saveNotMessage',
            'Your changes have not been saved.'
          )
          addToast({
            id: 'failure',
            type: 'negative',
            text
          })
          setLoading(true)
        })
    } else {
      setLoading(true)
      await getMapping(
        settingsForm.domain,
        settingsForm.tenantId,
        settingsForm.authorizationName,
        selectValue[0],
        nodeId,
        selectValueForRole[0],
        authStack,
        authProvider,
        HPBaseConfig,
        GoogleBaseConfig,
        AzureBaseConfig,
        mfeType
      )
        ?.then(() => {
          setHasChanges(false)
          const text = t(
            'third-party-idp-settings.settingsConfirmationModal.saveMessage',
            'Your changes have been saved.'
          )
          addToast({
            id: 'success',
            type: 'positive',
            text
          })
          setLoading(false)
        })
        .catch(() => {
          setLoading(true)
          const text = t(
            'third-party-idp-settings.settingsConfirmationModal.saveNotMessage',
            'Your changes have not been saved.'
          )
          addToast({
            id: 'failure',
            type: 'negative',
            text
          })
        })
    }
    setSettingsForm({
      domain: settingsForm.domain,
      tenantId: settingsForm.tenantId,
      authorizationName: settingsForm.authorizationName,
      hasChanges: false,
      errorMessage: null,
      errorTenant: null,
      errorAuth: null
    })
  }

  const fetchIdentityStorageDetails = useCallback(async () => {
    await fetchIdentityStorage(nodeId, authStack, authProvider, mfeType)
      ?.then(({ data }) => {
        const identityConfig = data?.content[data.content.length - 1]
        identityConfig.idp_type ===
        t(
          'third-party-idp-settings.settings.identifyProvidersSelected1',
          'Azure'
        )
          ? setFlag(true)
          : setFlag(false)
        setSelectValue([identityConfig.idp_type])
        setConfigDomain('@' + identityConfig.identity)
        setConfigAuthName(identityConfig.authorization_name)
        setConfigTenantId(identityConfig?.idp_tenant_id)
      })
      .catch(() => {
        setIsLoading(false)
        setSelectValue([
          t('third-party-idp-settings.settings.identifyProvidersSelected', 'HP')
        ])
        setConfigDomain('')
        setConfigAuthName(
          t('third-party-idp-settings.settings.identifyProvidersSelected', 'HP')
        )
      })
    setIsLoading(false)
  }, [authProvider, authStack, mfeType, nodeId, t])

  const fetchRoleForTenant = useCallback(async () => {
    const basePermSet = properties?.permissionSet

    await getPermissionSets(nodeId, authStack, authProvider, mfeType)?.then(
      (data) => {
        const roles = data.contents
        const tempArray = []

        roles.map((role) => {
          const tenantPermId = basePermSet?.filter((permId) =>
            permId.rolePermId.includes(role.uuid)
          )?.[0] || { rolePermId: role.uuid, label: role.name }

          tempArray.push(tenantPermId)
        })

        setRolesArray(tempArray)
      }
    )
  }, [mfeType, nodeId, authStack, authProvider, properties?.permissionSet])

  const getRoles = () => {
    return rolesArray.map((role) => ({
      value: role?.rolePermId,
      label: role?.label
    }))
  }

  const fetchConfigDetails = useCallback(async () => {
    await fetchOnboarding(nodeId, authStack, authProvider, mfeType)
      ?.then(async ({ data }) => {
        const UUID = data?.content[data.content.length - 1]
        const permId = UUID.perm_id
        setSelectValueForRole([permId])
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [authProvider, authStack, mfeType, nodeId])

  const fetchSavedData = () => {
    fetchRoleForTenant()
    fetchIdentityStorageDetails()
    fetchConfigDetails()
    setSettingsForm({
      domain: configDomain,
      tenantId: configTenantId,
      authorizationName: configAuthName,
      hasChanges: false,
      errorMessage: null,
      errorTenant: null,
      errorAuth: null
    })

    setHasChanges(false)
  }

  useEffect(() => {
    fetchSavedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let allowNavigation = true
    if (navigation) {
      const unregisterCallback = navigation?.block((e) => {
        if (window.location.pathname == e.pathname) return false
        if (hasChanges) {
          allowNavigation = false
          setModal((prevState) => {
            return {
              show: !prevState.show,
              location: e,
              unregisterCallback
            }
          })
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return allowNavigation as any
      })

      return () => unregisterCallback()
    }
  }, [hasChanges, navigation])

  /* istanbul ignore next */
  const flagForButton = () => {
    if (
      selectValue[0] !=
      t('third-party-idp-settings.settings.identifyProvidersSelected1', 'Azure')
    ) {
      return (
        isDisabled ||
        selectValueForRole[0] == '' ||
        !settingsForm.hasChanges ||
        Boolean(settingsForm.errorMessage) ||
        Boolean(settingsForm.errorTenant) ||
        settingsForm.domain.length === 1 ||
        restrictedDomains.some(
          (item) => item === settingsForm.domain.slice(1)
        ) ||
        settingsForm.authorizationName.length === 0
      )
    } else {
      return (
        isDisabled ||
        selectValueForRole[0] == '' ||
        !settingsForm.hasChanges ||
        Boolean(settingsForm.errorMessage) ||
        Boolean(settingsForm.errorTenant) ||
        settingsForm.domain.length <= 1 ||
        restrictedDomains.some(
          (item) => item === settingsForm.domain.slice(1)
        ) ||
        settingsForm.tenantId.length === 0 ||
        settingsForm.authorizationName.length === 0
      )
    }
  }

  const selectBoxesForIDPTab = () => {
    return (
      <>
        <BasicSelectItemStyle>
          <BasicTextItemTitle data-testid="selectbox-title">
            {t(
              'third-party-idp-settings.settings.identityProviders',
              'Identity Providers'
            )}
          </BasicTextItemTitle>
          {isLoading ? (
            <ProgressIndicator
              appearance="circular"
              behavior="indeterminate"
              data-testid="settings-form-elements-selectbox-progress-indicator"
            />
          ) : (
            <BasicTextItem>
              {
                <Select
                  data-testid="my-organization-select-box"
                  id="select-usage"
                  className="select-box-user-role"
                  options={selectValues}
                  clearIcon={false}
                  value={selectValue}
                  onChange={handleChange}
                />
              }
            </BasicTextItem>
          )}
        </BasicSelectItemStyle>
        <BasicSelectItemStyle>
          <BasicTextItemTitle data-testid="selectbox-title-2">
            {t('third-party-idp-settings.settings.role', 'User Role')}
          </BasicTextItemTitle>
          {isLoading ? (
            <ProgressIndicator
              appearance="circular"
              behavior="indeterminate"
              data-testid="settings-form-elements-selectbox-progress-indicator"
            />
          ) : (
            <BasicTextItem>
              {
                <Select
                  data-testid="my-organization-select-box-user-role"
                  id="select-usage-2"
                  className="select-box-user-role"
                  placeholder={t(
                    'third-party-idp-settings.settings.selectOption',
                    'Select Option'
                  )}
                  options={getRoles()}
                  clearIcon={false}
                  value={selectValueForRole}
                  onChange={handleChangeForUserRole}
                />
              }
            </BasicTextItem>
          )}
        </BasicSelectItemStyle>
      </>
    )
  }

  const renderNavigationTab = () => {
    return (
      <Tabs
        className="idp-tabs-wrapper"
        mode="extended"
        selectedTabId={'1'}
        tabs={[
          {
            id: '1',
            label: t('third-party-idp-settings.settings.iDP', 'IDP'),
            content: (
              <CardStyle data-testid="idp-settings-card">
                <CardTitle data-testid="idp-settings-card-title">
                  {t('third-party-idp-settings.settings.iDP', 'IDP')}
                  <Tooltip
                    id="idp-settings-tooltip"
                    arrow
                    content={t(
                      'third-party-idp-settings.tooltip.detailsIDP',
                      'The IDP (Identity Provider) option allows user to connect with sign in environment they need.The option provides the authentication method this customer signs in with. The user of this customer signs in with a defined authentication method.'
                    )}
                    placement="trailing"
                  >
                    <IconInfo />
                  </Tooltip>
                </CardTitle>
                <CardTitleBottomHr></CardTitleBottomHr>
                {selectBoxesForIDPTab()}
                <BasicTextItemStyle>
                  {renderDomainTextbox()}
                  {flag ? renderTenantIdTextbox() : null}
                  {renderAuthNameTextbox()}
                </BasicTextItemStyle>
              </CardStyle>
            )
          }
        ]}
      />
    )
  }

  return (
    <Container data-testid="navigation-tabs-wrapper">
      {renderNavigationTab()}{' '}
      {hasChanges && (
        <ContextualFooter
          data-testid="idp-settings-bottom-footer"
          onCancel={() => fetchSavedData()}
          onSave={() => {
            createConfig()
          }}
          loading={loading}
          flag={flagForButton()}
          t={t}
        />
      )}
      <NavigationBlockerModal
        data-testid="navigation-blocker-modal"
        showModal={!!modal?.show}
        OnClose={() => {
          setModal((prev) => ({
            ...prev,
            show: false,
            location: undefined
          }))
        }}
        OnConfirm={() => {
          const { unregisterCallback, location } = modal
          unregisterCallback?.()
          navigation?.push(location)
          setModal({ show: false })
        }}
        modalTitle={t(
          'third-party-idp-settings.settingsConfirmationModal.settingNotSaved',
          'Setting Not Saved'
        )}
      />
    </Container>
  )
}

export default MainComponent
