import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  svg {
    width: 36px !important;
    height: 36px !important;
  }
  .settings-confirmation-modalBox {
    width: 456px;
    height: 48px;
    margin: 0 0 ${primitives.layout.size5}px ${primitives.layout.size2}px;
  }
`
