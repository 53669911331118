import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  color: ${primitives.color.gray12};
  font-family: ${primitives.typography.family0} !important;

  h4 {
    font-family: ${primitives.typography.family0};
    font-size: ${primitives.typography.size4};
    line-height: ${primitives.typography.lineHeight3};
    margin-bottom: 18px;
    flex-grow: 9;
  }
  .account-tabs-wrapper {
    div:first-child {
      width: 55px;
      height: 48px;
      min-width: auto;
      justify-content: center;
      > div > span {
        line-height: ${primitives.typography.lineHeight2};
        font-size: ${primitives.typography.size2};
      }
    }
  }

  .idp-settings-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: block;
    margin: 0;
    height: 64px;
    right: 0;
    z-index: 3;
    animation: 1s ease 0s 1 normal none running animatebottom;
    li {
      align-items: center;
      border-color: transparent;
      border-radius: 0px;
      border-style: solid;
      border-width: 0px 1px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      padding: 14px ${primitives.layout.size6}px;
      padding-left: 284px;
      position: relative;
      width: 100%;
      background: #ebf9ff;
    }
  }
  #cancel-btn {
    margin-right: 10px !important;
    height: 36px;
    width: 112px;
    max-width: 112px;
    min-width: 112px;
    border-radius: ${primitives.layout.cornerRadius2}px;
  }
  #save-btn {
    border-radius: ${primitives.layout.cornerRadius2}px;
    height: 36px;
    span {
      font-family: ${primitives.typography.family0};
      font-size: ${primitives.typography.size2};
      line-height: ${primitives.typography.lineHeight2};
      letter-spacing: 0em;
      text-align: center;
    }
  }
  .settings-confirmation-modal-content {
    font-family: ${primitives.typography.family0}!important;
    font-size: ${primitives.typography.size3} !important;
    line-height: ${primitives.typography.lineHeight3} !important;
    letter-spacing: 0em;
    text-align: left;
    width: 310px;
  }
`
export const CardStyle = styled.section`
  padding: 14px ${primitives.layout.size6}px ${primitives.layout.size5}px
    ${primitives.layout.size5}px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
  border-radius: ${primitives.layout.cornerRadius4}px;
  margin-top: ${primitives.layout.size5}px;
`
export const CardTitle = styled.div`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight4};
  margin-bottom: ${primitives.layout.size4}px;
  svg {
    width: 20px;
    height: 20px;
    margin: 0 0 ${primitives.layout.size1}px 6px;
    position: relative;
    vertical-align: middle;
    color: ${primitives.color.gray6};
  }
  #idp-settings-tooltip {
    font-family: ${primitives.typography.family0}!important;
    font-size: ${primitives.typography.size1} !important;
    line-height: ${primitives.typography.lineHeight1} !important;
  }
`
export const CardTitleBottomHr = styled.div`
  height: 1px;
  position: relative;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 20px;
  background-color: #ebebeb;
`
export const TextBlockStyle = styled.div`
  display: flex;
  flex-direction: row;
`
export const TextItemStyle = styled.div`
  border-left: 3px solid #ebebeb;
  padding-left: 15px;
  padding-right: 50px;
`

export const TextItemTitle = styled.div`
  color: ${primitives.color.gray6};
  font-size: ${primitives.typography.size0};
  text-transform: uppercase;
`
export const TextItem = styled.div`
  line-height: ${primitives.typography.lineHeight3};
  font-size: ${primitives.typography.size2};
  font-family: ${primitives.typography.family0};
`
export const BasicSelectItemStyle = styled.div`
  display: block;
  height: 60px;
  margin-bottom: ${primitives.layout.size4}px;
  .select-box-user-role > div {
    height: 36px !important;
  }
`
export const BasicTextItemStyle = styled.div`
  display: block;
  margin-bottom: ${primitives.layout.size4}px;
  > div:first-child,
  > div:first-child + div {
    margin-bottom: ${primitives.layout.size4}px;
  }
  > div:last-child {
    margin-bottom: 0 !important;
  }
`
export const BasicTextItemTitle = styled.div`
  font-size: ${primitives.typography.size2};
  font-family: ${primitives.typography.family0};
  margin-bottom: 2px;
  line-height: ${primitives.typography.lineHeight2};
`
export const BasicTextItem = styled.div`
  line-height: ${primitives.typography.lineHeight2};
  width: 456px !important;
  display: flex;
  flex-direction: column;
  span.vn-checkbox__span {
    width: 20px !important;
    height: 20px !important;
  }
  .settings-form-elements-textbox,
  .settings-form-elements-text-2 {
    .vn-input {
      min-height: 36px !important;
    }
  }
  #select-usage-2 {
    .vn-select__placeholder {
      min-height: 0px !important;
    }
  }
  .vn-checkbox__span--text {
    font-size: ${primitives.typography.size2};
  }
  label {
    margin-bottom: ${primitives.layout.size3}px;
  }
  div:first-child > div {
    line-height: ${primitives.typography.lineHeight2};
    font-size: ${primitives.typography.size2};
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    span,
    input {
      font-size: ${primitives.typography.size2};
      line-height: ${primitives.typography.lineHeight2};
    }
  }
  span > div {
    display: flex;
    align-items: center;
  }
  div:last-child {
    min-height: 36px !important;
  }
  #settings-form-elements-textbox {
    height: 36px !important;
    width: 456px !important;
    padding-left: 12px;
    padding-right: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  #settings-form-elements-textbox-2 {
    height: 36px !important;
    width: 456px !important;
    padding-left: 12px;
    padding-right: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  #settings-form-elements-textbox-3 {
    height: 36px !important;
    width: 456px !important;
    padding-left: 12px;
    padding-right: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`
export const IconHelpStyle = styled.div`
  display: flex;
  > svg {
    color: #027aae;
  }
  div:first-child {
    padding-right: 6px;
  }
`
export const TitleBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight3};
  margin-bottom: 15px;
`
export const ActionButtonsStyle = styled.div`
  flex-grow: 1;
  text-align: right;
  button {
    height: 30px;
    margin: 0;
  }
`
export const ModalBox = styled.div`
  margin: 0 0 ${primitives.layout.size5}px 0;
`
export const ModalDescription = styled.div`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size2};
  font-weight: 300;
  line-height: ${primitives.typography.lineHeight3};
  letter-spacing: 0em;
  text-align: left;
`
export const ModalFooterBtnGrp = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const CheckboxListStyle = styled.div`
  display: flex;
`
