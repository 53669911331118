import React from 'react'
import { Button, IconWarningAlt, Modal } from '@veneer/core'
import ModalTitle from 'src/Shared-Components/ModalTitle'
import useShellRootContext from 'src/contexts/ShellRoot/useShellRootContext'
import * as S from './styles'

const NavigationBlockerModal = ({
  showModal,
  OnConfirm,
  OnClose,
  modalTitle
}) => {
  const { t = (_key: string, defaultValue: string) => defaultValue } =
    useShellRootContext()

  return (
    <Modal
      show={showModal}
      id="navigation-blocker-modal"
      className="navigation-blocker-modal"
      title=""
      footer={
        <S.ModalFooterBtnGrp className="vn-button-group--responsive">
          <Button
            appearance="secondary"
            onClick={OnClose}
            data-testid="navigation-blocker-cancel-button"
          >
            {t(
              'third-party-idp-settings.settingsConfirmationModal.modalCancel',
              'Cancel'
            )}
          </Button>
          <Button
            appearance="primary"
            onClick={OnConfirm}
            data-testid="navigation-blocker-leave-button"
          >
            {t(
              'third-party-idp-settings.settingsConfirmationModal.leave',
              'Leave'
            )}
          </Button>
        </S.ModalFooterBtnGrp>
      }
    >
      <S.ModalBox data-testid="navigation-blocker-modalBox">
        <ModalTitle>
          <IconWarningAlt
            color="darkOrange6"
            filled
            size={48}
          />{' '}
          <p
            className="title-small"
            data-testid="navigation-blocker-modal-title"
          >
            {modalTitle}
          </p>{' '}
        </ModalTitle>
      </S.ModalBox>
      <S.ModalDescription
        className="body-large"
        data-testid="navigation-blocker-modal-description"
      >
        <p>
          {t(
            'third-party-idp-settings.settingsConfirmationModal.leaveThisPage',
            'Are you sure you want to leave this page?'
          )}
        </p>
        <p>
          {t(
            'third-party-idp-settings.settingsConfirmationModal.unsavedChanges',
            'All unsaved changes will be lost.'
          )}
        </p>
      </S.ModalDescription>
    </Modal>
  )
}

export default NavigationBlockerModal
